import { CategoryFields } from 'src/components/MultistepForm/shared/types';

export const links = {
  TRIP_PLAN: '/keliones-planas',
  TRIP_FORM: '/unikali-kelione',
  TRIPS: '/keliones',
  COUNTRIES: '/salys',
  SEASONS: '/sezonas',
  ACTIVITIES: '/veiklos',
  NOT_FOUND: '/404',
  NOVATURAS: '/novaturo-keliones',
};

export const categoryFields = {
  PEOPLE: 'zmones',
  DAYS: 'dienos',
  ACTIVITIES: 'veiklos',
  SEASON: 'sezonas',
  COUNTRIES: 'salys',
  APARTMENTS: 'apartamentai',
  CATERING: 'maitinimas',
};

export const initialCategoryFields: CategoryFields = {
  zmones: 0,
  dienos: [],
  veiklos: [],
  sezonas: [],
  salys: [],
  apartamentai: [],
  maitinimas: [],
};

export const categoryFieldsLabels = {
  zmones: 'Keliautojų skaičius',
  dienos: 'Kelionės trukmė',
  veiklos: 'Veikla',
  sezonas: 'Data',
  salys: 'Šalys',
  apartamentai: 'Nakvynė',
  maitinimas: 'Maitinimas',
};

export const storageKeys = {
  FORM_VALUES: 'formValues',
  CURRENT_STEP: 'currentStep',
};

export const neutralOptionValue = 'Nesvarbu';
