import { createGlobalStyle } from 'styled-components';

import Poppins400 from 'src/static/fonts/poppins-v20-latin-ext-regular.woff2';
import Poppins500 from 'src/static/fonts/poppins-v20-latin-ext-500.woff2';
import Poppins700 from 'src/static/fonts/poppins-v20-latin-ext-700.woff2';

const FontStyles = createGlobalStyle`
   @font-face {
        font-display: swap;
        font-family: "Poppins";
        font-style: normal;
        font-weight: 400;
        src: url(${Poppins400}) format("woff2");
    }

    @font-face {
        font-display: swap;
        font-family: "Poppins";
        font-style: normal;
        font-weight: 500;
        src: url(${Poppins500}) format("woff2");
    }

    @font-face {
        font-display: swap;
        font-family: "Poppins";
        font-style: normal;
        font-weight: 700;
        src: url(${Poppins700}) format("woff2");
    }
`;

export default FontStyles;
