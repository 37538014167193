export const colors = {
  white: '#ffffff',
  blue100: '#dbe4ee',
  blue200: '#81a4cd',
  blue300: '#3e7cb1',
  blue500: '#054A91',
  grey500: '#949494',
  black: '#3a3a3a',
  orange500: '#f26419',
};
