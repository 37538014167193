import algoliasearch from 'algoliasearch/lite';
import { createRef, default as React, useRef, useState } from 'react';
import { InstantSearch } from 'react-instantsearch-dom';
import styled from 'styled-components';

import useClickOutside from './use-click-outside';
import SearchBox from 'src/components/Search/SearchBox/SearchBox';
import SearchResult from 'src/components/Search/SearchResult/SearchResult';

const SearchRoot = styled.div`
  margin: 0.6em 0;
  position: relative;
`;

const indices = [{ name: `PAGES`, title: `PAGES` }];

interface SearchProps {
  scrolled: boolean;
  className: string;
}

const Search: React.FC<SearchProps> = ({ scrolled, className }) => {
  const rootRef = createRef() as React.MutableRefObject<HTMLDivElement>;
  const searchBoxRef = useRef();
  const [query, setQuery] = useState();
  const [hasFocus, setFocus] = useState(false);
  const searchClient = algoliasearch(
    process.env.GATSBY_ALGOLIA_APP_ID,
    process.env.GATSBY_ALGOLIA_SEARCH_KEY
  );

  useClickOutside(rootRef, () => setFocus(false));

  const resetQuery = () => setQuery(undefined);

  return (
    <SearchRoot
      ref={rootRef}
      className={className}
      onClick={() => searchBoxRef.current.focus()}
    >
      <InstantSearch
        searchClient={searchClient}
        indexName={indices[0].name}
        onSearchStateChange={({ query }) => setQuery(query)}
      >
        <SearchBox
          onFocus={() => setFocus(true)}
          hasFocus={hasFocus}
          scrolled={scrolled}
          inputRef={searchBoxRef}
        />
        <SearchResult
          show={query && query.length > 2 && hasFocus}
          indices={indices}
          resetQuery={resetQuery}
        />
      </InstantSearch>
    </SearchRoot>
  );
};

export default Search;
