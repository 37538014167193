export const grid = {
  flexboxgrid: {
    gridSize: 12,
    gutterWidth: 3,
    outerMargin: 2,
    mediaQuery: 'only screen',
    breakpoints: {
      xs: 0,
      sm: 36,
      md: 48,
      lg: 62,
    },
  },
};
