import styled from 'styled-components';
import React, { FC, ReactNode, useState, useEffect, useRef } from 'react';

import IconLabel, { IconSize } from 'src/components/IconLabel';
import { DropdownIcon } from 'src/assets/icons';
import { colors } from 'src/theme/colors';
import { mediaQuery } from 'src/theme/breakpoints';

const DropdownContent = styled.nav`
  background-color: ${colors.white};
  border-radius: 10px;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.5);
  box-sizing: border-box;
  filter: none;
  left: 0;
  margin-top: 10px;
  min-width: 200px;
  padding: 20px;
  position: absolute;

  & > :not(:first-child) {
    display: block;
    margin-top: 15px;
  }

  ${mediaQuery('lg', 'max')} {
    position: relative;
    width: 100%;
  }
`;

const DropdownButton = styled.button<Pick<DropdownProps, 'isDark'>>`
  align-items: center;
  background-color: transparent;
  border: none;
  cursor: pointer;
  display: flex;
  padding: 0;

  & > :last-child {
    margin-left: 12px;
  }

  &:focus:not(:focus-visible) {
    outline: 0;
  }

  & path {
    fill: ${({ isDark }) => (isDark ? `${colors.black}` : `${colors.white}`)};
  }

  ${mediaQuery('lg', 'max')} {
    width: 100%;
  }
`;

const Wrapper = styled.div`
  position: relative;
`;

interface DropdownProps {
  closeOnDropdownClick?: boolean;
  icon: ReactNode;
  iconSize: IconSize;
  label: string;
  children: ReactNode;
  isDark?: boolean;
}

const Dropdown: FC<DropdownProps> = ({
  closeOnDropdownClick,
  icon,
  iconSize,
  label,
  children,
  isDark,
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const contentRef = useRef<HTMLDivElement>(null);
  const triggerRef = useRef<HTMLButtonElement>(null);

  const handleGlobalClick = (e: Event) => {
    const target = e.target as HTMLElement;
    const contentNode = contentRef.current;
    const triggerNode = triggerRef.current;

    if (
      triggerNode &&
      triggerNode !== target &&
      !triggerNode.contains(target) &&
      !(
        !closeOnDropdownClick &&
        contentNode &&
        contentNode !== target &&
        contentNode.contains(target)
      )
    ) {
      setIsOpen(false);
    }
  };

  const handleEscClick = (e: KeyboardEvent) => {
    if (e.key === 'Escape') {
      setIsOpen(false);
    }
  };

  const open = () => {
    if (!isOpen) {
      setIsOpen(true);
      document.addEventListener('keydown', handleEscClick);
      document.addEventListener('click', handleGlobalClick);
    }
  };

  const close = () => {
    if (isOpen) {
      setIsOpen(false);
      document.removeEventListener('keydown', handleEscClick);
      document.removeEventListener('click', handleGlobalClick);
    }
  };

  const handleClick = () => {
    isOpen ? close() : open();
  };

  useEffect(() => {
    return () => {
      document.removeEventListener('click', handleGlobalClick);
    };
  }, []);
  return (
    <Wrapper>
      <DropdownButton
        aria-expanded={isOpen}
        aria-haspopup={true}
        onClick={handleClick}
        ref={triggerRef}
        type="button"
        isDark={isDark}
      >
        <IconLabel icon={icon} label={label} size={iconSize} isDark={isDark} />
        <DropdownIcon />
      </DropdownButton>
      <DropdownContent hidden={!isOpen} ref={contentRef}>
        {children}
      </DropdownContent>
    </Wrapper>
  );
};

export default Dropdown;
