exports.components = {
  "component---cache-caches-gatsby-plugin-offline-app-shell-js": () => import("./../../caches/gatsby-plugin-offline/app-shell.js" /* webpackChunkName: "component---cache-caches-gatsby-plugin-offline-app-shell-js" */),
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-apie-mus-tsx": () => import("./../../../src/pages/apie-mus.tsx" /* webpackChunkName: "component---src-pages-apie-mus-tsx" */),
  "component---src-pages-galerija-tsx": () => import("./../../../src/pages/galerija.tsx" /* webpackChunkName: "component---src-pages-galerija-tsx" */),
  "component---src-pages-igyvendinami-projektai-tsx": () => import("./../../../src/pages/igyvendinami-projektai.tsx" /* webpackChunkName: "component---src-pages-igyvendinami-projektai-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-keliones-planas-tsx": () => import("./../../../src/pages/keliones-planas.tsx" /* webpackChunkName: "component---src-pages-keliones-planas-tsx" */),
  "component---src-pages-keliones-tsx": () => import("./../../../src/pages/keliones.tsx" /* webpackChunkName: "component---src-pages-keliones-tsx" */),
  "component---src-pages-keliones-wp-product-slug-tsx": () => import("./../../../src/pages/keliones/{wpProduct.slug}.tsx" /* webpackChunkName: "component---src-pages-keliones-wp-product-slug-tsx" */),
  "component---src-pages-kontaktai-tsx": () => import("./../../../src/pages/kontaktai.tsx" /* webpackChunkName: "component---src-pages-kontaktai-tsx" */),
  "component---src-pages-novaturo-keliones-tsx": () => import("./../../../src/pages/novaturo-keliones.tsx" /* webpackChunkName: "component---src-pages-novaturo-keliones-tsx" */),
  "component---src-pages-pirkimo-salygos-taisykles-tsx": () => import("./../../../src/pages/pirkimo-salygos-taisykles.tsx" /* webpackChunkName: "component---src-pages-pirkimo-salygos-taisykles-tsx" */),
  "component---src-pages-privatumo-politika-tsx": () => import("./../../../src/pages/privatumo-politika.tsx" /* webpackChunkName: "component---src-pages-privatumo-politika-tsx" */),
  "component---src-pages-unikali-kelione-tsx": () => import("./../../../src/pages/unikali-kelione.tsx" /* webpackChunkName: "component---src-pages-unikali-kelione-tsx" */),
  "component---src-templates-product-category-tsx": () => import("./../../../src/templates/ProductCategory.tsx" /* webpackChunkName: "component---src-templates-product-category-tsx" */)
}

