import React, { FC } from 'react';

import Card from 'src/components/Card';
import Button, { ButtonAppearance } from 'src/components/Button';
import Grid from 'src/components/Grid/Grid';

interface CardsGridProps {
  columns: number;
  cards: any;
  small?: boolean;
  button?: string;
}

const CardsGrid: FC<CardsGridProps> = ({ columns, small, button, cards }) => {
  return (
    <Grid columns={columns} small={small}>
      {cards &&
        cards.map((card, id) => (
          <Card
            key={id}
            image={card.image}
            title={card.title}
            url={card.link}
            button={card.button}
            small={card.small}
          />
        ))}
      {button && (
        <Button appearance={ButtonAppearance.CHARLIE} url={button}>
          Visos šalys
        </Button>
      )}
    </Grid>
  );
};

export default CardsGrid;
