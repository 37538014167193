import React from 'react';
import { connectSearchBox } from 'react-instantsearch-dom';
import styled from 'styled-components';

import { SearchIcon } from 'src/assets/icons';
import { colors } from 'src/theme/colors';
import { mediaQuery } from 'src/theme/breakpoints';

const SearchBoxWrapper = styled.form<{ scrolled: boolean }>`
  display: flex;
  margin-bottom: 0;

  svg {
    color: ${({ scrolled }) => (scrolled ? colors.black : colors.blue100)};
    height: 16px;
    margin-top: 2px;
    pointer-events: none;
    z-index: 3;

    ${mediaQuery('lg', 'max')} {
      margin-top: 4px;
      transform: scale(1.4);
    }
  }
`;

const SearchBoxInputWrapper = styled.div<{
  scrolled: boolean;
  hasFocus: boolean;
}>`
  height: 26px;
  margin-left: 12px;
  position: relative;

  .SearchInput {
    background-color: transparent;
    border: none;
    border-radius: 2px;
    color: ${({ scrolled }) => (scrolled ? colors.black : colors.white)};
    cursor: text;
    display: block;
    font-size: 12px;
    line-height: 16px;
    outline: none;
    overflow: hidden;
    padding: 0;
    text-overflow: ellipsis;
    transition: 250ms ease-in-out;
    white-space: nowrap;
    width: 264px;

    &::placeholder {
      color: ${({ scrolled }) => (scrolled ? colors.black : colors.blue100)};
      text-overflow: ellipsis;
    }

    :placeholder-shown {
      text-overflow: ellipsis;
    }

    ${mediaQuery('md', 'max')} {
      opacity: ${({ hasFocus }) => (hasFocus ? '1' : '0')};
      width: ${({ hasFocus }) => (hasFocus ? '264px' : '0')};
    }

    ${mediaQuery(undefined, 'max', '480px')} {
      opacity: ${({ hasFocus }) => (hasFocus ? '1' : '0')};
      width: ${({ hasFocus }) => (hasFocus ? '124px' : '0')};
    }
  }

  &::before,
  &::after {
    background-color: ${({ scrolled }) =>
      scrolled ? colors.black : colors.blue100};
    bottom: 0;
    content: '';
    height: 1px;
    left: 0;
    opacity: 0.5;

    position: absolute;
    right: 0;
    transition: width 250ms ease-in-out;

    ${mediaQuery('md', 'max')} {
      width: ${({ hasFocus }) => (hasFocus ? 'calc(100% - 12px)' : '0')};
    }
  }

  &::after {
    opacity: 1;
    width: ${({ hasFocus }) => (hasFocus ? '100%' : '0')};
  }
`;

export default connectSearchBox(
  ({
    refine,
    currentRefinement,
    className,
    onFocus,
    hasFocus,
    scrolled,
    inputRef,
  }) => (
    <SearchBoxWrapper
      className={className}
      scrolled={scrolled}
      onSubmit={e => e.preventDefault()}
    >
      <SearchIcon />
      <SearchBoxInputWrapper hasFocus={hasFocus} scrolled={scrolled}>
        <input
          className="SearchInput"
          type="text"
          placeholder="Ieškokite savo svajonių kelionės, šalies, veiklos"
          aria-label="Search"
          onChange={e => refine(e.target.value)}
          value={currentRefinement}
          onFocus={onFocus}
          ref={inputRef}
        />
      </SearchBoxInputWrapper>
    </SearchBoxWrapper>
  )
);
