import { createGlobalStyle } from 'styled-components';

import theme from 'src/theme/theme';

export default createGlobalStyle`  
  body {
    font-family: ${theme.primaryFont};
    font-size: 13px;
    line-height: 1.5;
  }

  a {
    text-decoration: none;
  }
`;
