import React from 'react';
import styled, { ThemeProvider } from 'styled-components';
import 'sanitize.css';

import GlobalStyles from 'src/static/styles/global.css';
import theme from 'src/theme/theme';
import Header from 'src/components/Header';
import Footer from 'src/components/Footer';
import { links } from 'src/components/MultistepForm/shared/constants';
import FontStyles from 'src/theme/fonts';

const WrapperStyles = styled.div``;

const pagesWithoutHeader = [
  links.TRIP_FORM,
  links.TRIP_PLAN,
  links.NOT_FOUND,
  links.NOVATURAS,
];

const Layout: React.FC = ({ children }) => {
  const isWithHeader = (): boolean | undefined => {
    if (typeof window !== `undefined`) {
      const linksWithoutSlash = pagesWithoutHeader.map(page =>
        page.replaceAll('/', '')
      );
      const currentPage = location.pathname.replaceAll('/', '');

      return !linksWithoutSlash.includes(currentPage);
    }
  };

  return (
    <ThemeProvider theme={theme}>
      <FontStyles />
      <GlobalStyles />
      <WrapperStyles>
        {isWithHeader() && <Header />}
        {children}
        <Footer />
        {/* <Cookies /> */}
      </WrapperStyles>
    </ThemeProvider>
  );
};

export default Layout;
