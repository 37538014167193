import { Link } from 'gatsby';
import React from 'react';
import {
  connectStateResults,
  Highlight,
  Hits,
  Index,
} from 'react-instantsearch-dom';
import styled, { css } from 'styled-components';

import { colors } from 'src/theme/colors';
import { mediaQuery } from 'src/theme/breakpoints';

const Popover = css`
  background-color: ${colors.white};
  border-radius: 10px;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.5);
  box-sizing: border-box;
  filter: none;
  flex-direction: column;
  left: 0;
  margin-top: 10px;
  max-height: 70vh;
  max-width: 30em;
  min-width: 200px;
  -webkit-overflow-scrolling: touch;
  overflow-y: auto;
  padding: 20px;
  position: absolute;

  z-index: 2;

  ${mediaQuery('lg', 'max')} {
    width: 100%;
  }
`;

const SearchResultWrapper = styled.div<{ show?: boolean }>`
  display: ${({ show }) => (show ? `flex` : `none`)};
  ${Popover}

  .HitCount {
    justify-content: flex-end;
  }

  .Hits {
    ul {
      list-style: none;
      margin: 0;
      padding: 0;
    }

    li.ais-Hits-item {
      margin-bottom: 1em;
      a {
        color: ${colors.blue500};

        h4 {
          margin-bottom: 0.2em;
        }
      }
    }
  }

  .ais-PoweredBy {
    display: flex;
    font-size: 80%;
    justify-content: flex-end;

    svg {
      width: 70px;
    }
  }

  mark {
    background-color: ${colors.orange500};
  }
`;

const HitCount = connectStateResults(({ searchResults }) => {
  const hitCount = searchResults && searchResults.nbHits;

  const getResultText = () => {
    if (hitCount === 1) {
      return 'rezultatas';
    }

    return hitCount > 9 ? 'rezultatų' : 'rezultatai';
  };

  return (
    <div className="HitCount">
      {hitCount ? (
        <>
          <b>{hitCount}</b> {getResultText()}
        </>
      ) : (
        'Atitikmenų nerasta'
      )}
    </div>
  );
});

const PageHit = ({ hit }) => {
  return (
    <Link to={hit.slug}>
      <h4>
        <Highlight attribute="title" hit={hit} tagName="mark" />
      </h4>
    </Link>
  );
};

const HitsInIndex = ({ index, resetQuery }) => {
  return (
    <Index indexName={index.name}>
      <HitCount />
      <div onClick={() => resetQuery()}>
        <Hits className="Hits" hitComponent={PageHit} />
      </div>
    </Index>
  );
};

interface SearchResultProps {
  indices: any;
  show: boolean | undefined;
  resetQuery: () => void;
  className?: string;
}

const SearchResult: React.FC<SearchResultProps> = ({
  indices,
  show,
  resetQuery,
  className,
}) => {
  return (
    <SearchResultWrapper className={className} show={show}>
      {indices.map(index => (
        <HitsInIndex index={index} key={index.name} resetQuery={resetQuery} />
      ))}
      {/*<PoweredBy />*/}
    </SearchResultWrapper>
  );
};

export default SearchResult;
