import React, { FC } from 'react';
import styled from 'styled-components';

import { colors } from 'src/theme/colors';
import { mediaQuery } from 'src/theme/breakpoints';

const StyledBurger = styled.button`
  background-color: transparent;
  border: none;
  cursor: pointer;
  display: none;
  flex-direction: column;
  height: 17px;
  justify-content: space-between;
  padding: 0;
  width: 42px;
  z-index: 2;

  &:focus:not(:focus-visible) {
    outline: 0;
  }

  ${mediaQuery('lg', 'max')} {
    display: flex;
  } ;
`;

const Line = styled.span<Pick<BurgerProps, 'isDark' | 'isOpen'>>`
  background-color: ${({ isDark }) =>
    isDark ? `${colors.black}` : `${colors.white}`};
  border-radius: 2px;
  height: 1px;
  transform-origin: left center;
  transition: transform 0.3s ease;
  width: 32px;
  z-index: 2;

  &:first-of-type {
    transform: ${({ isOpen }) =>
      isOpen ? 'rotate(45deg) translate3d(-2px, 0, 0)' : 'none'};
  }

  &:nth-of-type(2) {
    transform: ${({ isOpen }) =>
      isOpen ? 'rotate(-45deg) translate3d(-11px, 7px, 0);' : 'none'};
    transition-delay: 150ms;
  }

  &:last-of-type {
    opacity: ${({ isOpen }) => (isOpen ? '0' : '1')};
    transition-delay: 300ms;
  }
`;

interface BurgerProps {
  isOpen?: boolean;
  isDark?: boolean;
  setIsOpen: (isOpen: boolean) => void;
}

const Burger: FC<BurgerProps> = ({ isOpen, isDark, setIsOpen }) => (
  <StyledBurger onClick={() => setIsOpen(!isOpen)}>
    <Line isOpen={isOpen} isDark={isDark} />
    <Line isOpen={isOpen} isDark={isDark} />
    <Line isOpen={isOpen} isDark={isDark} />
  </StyledBurger>
);

export default Burger;
