import React, { FC } from 'react';
import styled from 'styled-components';

import { FBIcon, IGIcon, PinterestIcon } from 'src/assets/icons';
import { colors } from 'src/theme/colors';

const Wrapper = styled.div`
  align-items: center;
  display: flex;
  justify-content: center;

  & > :not(:last-child) {
    margin-right: 20px;
  }
`;

const Link = styled.a`
  background-color: ${colors.blue200};
  border-radius: 50%;
  padding: 10px;
`;

interface SocialIconsProps {
  facebook?: string;
  instagram?: string;
  pinterest?: string;
}

const SocialIcons: FC<SocialIconsProps> = ({
  facebook,
  instagram,
  pinterest,
}) => (
  <Wrapper>
    {facebook && (
      <Link href={facebook} target="_blank" rel="noreferrer">
        <FBIcon />
      </Link>
    )}
    {instagram && (
      <Link href={instagram} target="_blank" rel="noreferrer">
        <IGIcon />
      </Link>
    )}
    {pinterest && (
      <Link href={pinterest} target="_blank" rel="noreferrer">
        <PinterestIcon />
      </Link>
    )}
  </Wrapper>
);

export default SocialIcons;
