import React, { FC } from 'react';
import styled, { css } from 'styled-components';

import { IconSize } from './constants';
import { colors } from 'src/theme/colors';

const IconWrapper = styled.div<Pick<IconLabelProps, 'isDark' | 'size'>>`
  & path {
    fill: ${({ isDark }) => (isDark ? `${colors.black}` : `${colors.white}`)};
  }

  & svg {
    ${({ size }) =>
      size === IconSize.SMALL &&
      css`
        height: 20px;
        width: 20px;
      `}

    ${({ size }) =>
      size === IconSize.MEDIUM &&
      css`
        height: 30px;
        width: 30px;
      `}

    ${({ size }) =>
      size === IconSize.BIG &&
      css`
        height: 40px;
        width: 40px;
      `}
  }
`;

const Label = styled.span<Pick<IconLabelProps, 'isDark' | 'isBiggerText'>>`
  color: ${({ isDark }) => (isDark ? `${colors.black}` : `${colors.white}`)};
  display: inline-block;
  font-size: ${({ isBiggerText }) => (isBiggerText ? `16px` : `13px`)};
  font-weight: ${({ isBiggerText }) => (isBiggerText ? `500` : `400`)};
  margin-left: 10px;
  padding-bottom: 2px;
  position: relative;

  &:after {
    background: none repeat scroll 0 0 transparent;
    background-color: ${({ isDark }) =>
      isDark ? `${colors.black}` : `${colors.white}`};
    bottom: 0;
    content: '';
    display: block;
    height: 1px;
    left: 0;
    position: absolute;
    transition: width 0.3s ease 0s, left 0.3s ease 0s;
    width: 0;
  }
`;

const StyledIconLabel = styled.div`
  align-items: center;
  display: flex;
  &:hover ${Label}:after {
    left: 0;
    width: 100%;
  }
`;

interface IconLabelProps {
  icon: React.ReactNode;
  label: string;
  size: IconSize;
  isDark?: boolean;
  isBiggerText?: boolean;
}

const IconLabel: FC<IconLabelProps> = ({
  icon,
  label,
  size,
  isDark,
  isBiggerText,
}) => (
  <StyledIconLabel>
    <IconWrapper size={size} isDark={isDark}>
      {icon}
    </IconWrapper>
    <Label isDark={isDark} isBiggerText={isBiggerText}>
      {label}
    </Label>
  </StyledIconLabel>
);

export default IconLabel;
