import { MutableRefObject, useEffect } from 'react';

const events = [`mousedown`, `touchstart`];

export default (
  ref: MutableRefObject<HTMLDivElement>,
  onClickOutside: () => void
): any => {
  const isOutside = element => !ref.current || !ref.current.contains(element);
  const onClick = event => {
    if (isOutside(event.target)) {
      onClickOutside();
    }
  };

  useEffect(() => {
    for (const event of events) {
      document.addEventListener(event, onClick);
    }
    return () => {
      for (const event of events) document.removeEventListener(event, onClick);
    };
  });
};
