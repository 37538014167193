import React, { FC } from 'react';
import styled, { css } from 'styled-components';

import { mediaQuery } from 'src/theme/breakpoints';

const GridWrapper = styled.div<GridProps>`
  display: grid;
  grid-column-gap: 16px;
  grid-row-gap: 20px;
  padding-top: 20px;

  ${({ small, columns }) =>
    !small &&
    css`
      grid-template-columns: repeat(${columns - 2}, 1fr);

      ${mediaQuery('sm')} {
        grid-template-columns: repeat(${columns - 1}, 1fr);
      }

      ${mediaQuery('lg')} {
        grid-template-columns: repeat(${columns}, 1fr);
      }
    `}

  ${({ small, columns }) =>
    small &&
    css`
      grid-column-gap: 12px;
      grid-row-gap: 12px;
      grid-template-columns: repeat(${columns}, 1fr);
      padding-top: 0;
    `}
`;

interface GridProps {
  columns: number;
  small?: boolean;
  children: React.ReactNode;
}

const Grid: FC<GridProps> = ({ columns, small, children }) => {
  return (
    <GridWrapper columns={columns} small={small}>
      {children}
    </GridWrapper>
  );
};

export default Grid;
