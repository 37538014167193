import React, { FC } from 'react';
import styled, { css } from 'styled-components';
import { GatsbyImage } from 'gatsby-plugin-image';
import { Link } from 'gatsby';

import { mediaQuery } from 'src/theme/breakpoints';
import { colors } from 'src/theme/colors';

const Button = styled.div`
  background-color: ${colors.orange500};
  border-radius: 10px;
  color: ${colors.white};
  display: none;
  font-size: 12px;
  font-weight: 700;
  letter-spacing: 1px;
  margin-top: 10px;
  padding: 5px 10px;
  text-transform: uppercase;
  z-index: 1;

  ${mediaQuery('md')} {
    display: block;
    opacity: 0;
    transform: translateY(-5px);
    transition: opacity 300ms, transform 300ms;
  } ;
`;

const Title = styled.span<Pick<CardProps, 'small' | 'button'>>`
  color: ${colors.white};
  font-size: ${props => (props.small ? '8px' : '14px')};
  font-weight: 700;
  letter-spacing: 0.8px;
  text-align: center;
  text-transform: uppercase;
  z-index: 1;

  ${mediaQuery('md')} {
    font-size: ${props => (props.small ? '12px' : '18px')};
    ${({ button }) =>
      button &&
      css`
        transform: translateY(10px);
        transition: transform 300ms;
      `}
  } ;
`;

const StyledLink = styled(Link)`
  &:hover ${Button} {
    display: block;
    opacity: 1;
    transform: translateY(0);
  }

  &:hover ${Title} {
    transform: translateY(0);
  }
`;

const BackgroundWrapper = styled.div<Pick<CardProps, 'small'>>`
  border-radius: 10px;
  height: 140px;
  max-height: 140px;
  max-width: 260px;
  position: relative;

  &:after {
    background-color: rgba(0, 0, 0, 0.4);
    border-radius: 10px;
    content: '';
    height: 100%;
    left: 0;
    position: absolute;
    top: 0;
    width: 100%;
  }

  ${({ small }) =>
    small &&
    css`
      height: 60px;

      ${mediaQuery('md')} {
        height: 92px;
        width: 92px;
      }
    `}
`;

const Background = styled(GatsbyImage)`
  border-radius: 10px;
  height: 100%;
  left: 0;
  position: absolute;
  top: 0;
  width: 100%;
`;

const ContentWrapper = styled.div`
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 100%;
  overflow: hidden;
  width: 100%;
`;

interface CardProps {
  image?: any;
  title: string;
  url: string;
  button?: boolean;
  small?: boolean;
}

const Card: FC<CardProps> = ({ image, title, url, button, small }) => (
  <StyledLink to={url}>
    <BackgroundWrapper small={small}>
      <Background image={image} alt={title} />
      <ContentWrapper>
        <Title button={button} small={small}>
          {title}
        </Title>
        {button && <Button>Rinktis keliones</Button>}
      </ContentWrapper>
    </BackgroundWrapper>
  </StyledLink>
);

export default Card;
