import React, { FC } from 'react';
import styled, { css } from 'styled-components';
import { useStaticQuery, graphql, Link } from 'gatsby';

import CardsGrid from 'src/components/CardsGrid';
import Logo from 'src/components/Logo';
import SocialIcons from 'src/components/SocialIcons';
import Newsletter from 'src/components/Newsletter';
import { colors } from 'src/theme/colors';
import { mediaQuery } from 'src/theme/breakpoints';
import { domain } from 'src/utils/constants';

const linkAnimation = css`
  color: ${colors.black};
  display: inline-block;
  margin-bottom: 10px;
  padding-bottom: 2px;
  position: relative;

  &:after {
    background: none repeat scroll 0 0 transparent;
    background-color: ${colors.black};
    bottom: 0;
    content: '';
    display: block;
    height: 1px;
    left: 0;
    position: absolute;
    transition: width 0.3s ease 0s, left 0.3s ease 0s;
    width: 0;
  }

  &:hover:after {
    left: 0;
    width: 100%;
  }
`;

const Wrapper = styled.footer`
  padding: 0 20px;
  position: relative;

  &:before {
    background-color: ${colors.blue500};
    content: '';
    height: 100%;
    left: 0;
    position: absolute;
    top: 60px;
    width: 100%;
    z-index: -1;
  }
`;

const Main = styled.div`
  background-color: ${colors.white};
  border-radius: 10px;
  box-shadow: 0 10px 50px rgba(0, 0, 0, 0.14);
  display: grid;
  grid-row-gap: 20px;
  grid-template-columns: 1fr;
  margin: 0 auto;
  max-width: 1088px;
  padding: 30px 10px;

  ${mediaQuery('md')} {
    grid-template-columns: repeat(2, 1fr);
  }

  ${mediaQuery('lg')} {
    grid-template-columns: repeat(3, 1fr);
    padding: 50px 10px;
  }
`;

const Column = styled.div`
  display: flex;
  flex-direction: column;
  padding: 0 10px;
  position: relative;

  ${mediaQuery('md')} {
    padding: 0 40px;
  }

  ${mediaQuery('lg')} {
    &:not(:first-child):before {
      background-color: ${colors.grey500};
      bottom: 0;
      content: '';
      left: 0;
      opacity: 0.2;
      position: absolute;
      top: 0;
      width: 1px;
    }
  }
`;

const Section = styled.section`
  align-items: flex-start;
  display: flex;
  flex-direction: column;

  &:not(:first-child) {
    margin-top: 20px;
  }
  ${mediaQuery('lg')} {
    &:not(:first-child) {
      margin-top: 30px;
    }
  }
`;

const Title = styled.p`
  font-weight: 700;
  letter-spacing: 0.8px;
  margin: 0 0 10px;
  text-transform: uppercase;

  ${mediaQuery('lg')} {
    margin: 0 0 20px;
  }
`;

const NavLink = styled(Link)`
  ${linkAnimation}
`;

const NavLinkExternal = styled.a`
  ${linkAnimation}
`;

const Text = styled.p`
  margin: 0 0 20px;
`;

const BottomContent = styled.div`
  margin: 36px auto 0;
  max-width: 1088px;
`;

const LogoWrapper = styled.div`
  align-items: center;
  display: flex;
  flex-direction: column;
  margin-bottom: 30px;
  position: relative;
  width: 100%;

  &:before,
  &:after {
    background-color: ${colors.white};
    content: '';
    height: 2px;
    position: absolute;
    top: 50%;
    width: 20%;
  }

  &:before {
    left: 33px;
  }

  &:after {
    right: 33px;
  }

  ${mediaQuery('sm')} {
    &:before,
    &:after {
      width: 30%;
    }
  }

  ${mediaQuery('lg')} {
    &:before,
    &:after {
      width: 38%;
    }
  }
`;

const Copyright = styled.div`
  align-items: center;
  color: ${colors.white};
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-top: 50px;
  text-align: center;
  width: 100%;

  & a {
    color: ${colors.white};
  }
`;

const Footer: FC = () => {
  const data = useStaticQuery(graphql`
    {
      countries: allFile(filter: { relativeDirectory: { eq: "footer" } }) {
        edges {
          node {
            name
            childImageSharp {
              gatsbyImageData(width: 200)
            }
          }
        }
      }
    }
  `);

  return (
    <Wrapper>
      <Main>
        <Column>
          <Section>
            <Title>Apie piligrimas.lt</Title>
            <NavLink to="/apie-mus">Apie mus</NavLink>
            <NavLink to="/igyvendinami-projektai">
              Įgyvendinami projektai
            </NavLink>
            <NavLink to="/galerija">Galerija</NavLink>
            <NavLink to="/kontaktai">Kontaktai</NavLink>
            <NavLinkExternal href={`${domain}/product/dovanu-kuponas/`}>
              Dovanų kuponas
            </NavLinkExternal>
          </Section>
          <Section>
            <Title>Prenumeruok!</Title>
            <Text>
              Prenumeruok mūsų naujienlaiškį ir gauk išskirtinius bei paskutinės
              minutės pasiūlymus:
            </Text>
            <Newsletter />
          </Section>
        </Column>
        <Column>
          <Section>
            <Title>Sąlygos</Title>
            <NavLink to="/pirkimo-salygos-taisykles">
              Pirkimo sąlygos ir taisyklės
            </NavLink>
            <NavLink to="/pirkimo-salygos-taisykles/#mokejimo-budai">
              Mokėjimo būdai
            </NavLink>
          </Section>
          <Section>
            <Title>Privatumas</Title>
            <NavLink to="/privatumo-politika">Privatumo politika</NavLink>
            <NavLink to="/privatumo-politika/#asmeniniai-duomenys">
              Asmeniniai duomenys
            </NavLink>
            <NavLink to="/privatumo-politika/#slapukai">
              Slapukų politika
            </NavLink>
          </Section>
        </Column>
        <Column>
          <Title>Kelionių kryptys</Title>
          <CardsGrid
            columns={3}
            small
            cards={data.countries.edges.map(({ node }) => ({
              image: node.childImageSharp.gatsbyImageData,
              title: node.name,
              link: `/salys/${node.name}`,
              small: true,
            }))}
          ></CardsGrid>
        </Column>
      </Main>
      <BottomContent>
        <LogoWrapper>
          <Logo />
        </LogoWrapper>
        <SocialIcons
          facebook="https://www.facebook.com/Piligrimas/"
          instagram="https://www.instagram.com/piligrimas_travel/"
          pinterest="https://www.pinterest.com/piligrimas_travel/_created/"
        />
        <Copyright>
          <strong>© 2021 Piligrimas.lt Visos teisės saugomos.</strong>
          <p>
            Website created by{' '}
            <a href="https://tamulaitis.com" target="_blank" rel="noreferrer">
              R. Tamulaitis
            </a>
            {` & `}
            <a
              href="https://lt.linkedin.com/in/kornelijusg"
              target="_blank"
              rel="noreferrer"
            >
              K. Glinskas
            </a>
          </p>
        </Copyright>
      </BottomContent>
    </Wrapper>
  );
};

export default Footer;
