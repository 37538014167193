import { DefaultTheme } from 'styled-components';

import { grid } from 'src/theme/grid';

const theme: DefaultTheme = {
  primaryFont: '"Poppins", sans-serif',
  ...grid,
};

export default theme;
