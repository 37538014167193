import styled, { css } from 'styled-components';
import React, { FC, ReactNode, useState, useEffect } from 'react';

import Logo from 'src/components/Logo';
import Navigation from 'src/components/Navigation';
import { colors } from 'src/theme/colors';
import { mediaQuery } from 'src/theme/breakpoints';
import { default as SearchComponent } from 'src/components/Search/Search';

const Wrapper = styled.header<Pick<HeaderProps, 'scrolled'>>`
  position: fixed;
  top: 0;
  transition: background-color 200ms;
  width: 100%;
  z-index: 3;

  ${({ scrolled }) =>
    scrolled &&
    css`
      background-color: ${colors.white};
      box-shadow: 0 1px 1px 0 rgba(65, 69, 73, 0.3),
        0 1px 3px 1px rgba(65, 69, 73, 0.15);
    `}
`;

const Content = styled.div<Pick<HeaderProps, 'scrolled'>>`
  align-items: center;
  box-sizing: content-box;
  display: flex;
  height: 100%;
  justify-content: space-between;
  margin: 0 auto;
  max-width: 1080px;
  padding: ${({ scrolled }) => (scrolled ? '10px 20px' : '20px 20px')};

  ${mediaQuery('lg')} {
    padding: ${({ scrolled }) => (scrolled ? '10px 40px' : '30px 40px')};
  }
`;

const Search = styled(SearchComponent)`
  margin-left: 16px;
  margin-right: auto;

  ${mediaQuery('lg')} {
    margin-left: 32px;
  }
`;

interface HeaderProps {
  menu?: ReactNode;
  scrolled?: boolean;
  isSurvey?: boolean;
}

const Header: FC<HeaderProps> = ({ isSurvey }) => {
  const [scroll, setScroll] = useState(false);

  const changeColors = () => {
    if (window && window.scrollY > 0) {
      setScroll(true);
    } else {
      setScroll(false);
    }
  };

  useEffect(() => {
    window.addEventListener('scroll', changeColors);
    return () => {
      window.removeEventListener('scroll', changeColors);
    };
  }, []);

  return (
    <Wrapper scrolled={scroll || isSurvey}>
      <Content scrolled={scroll || isSurvey}>
        <Logo scrolled={scroll || isSurvey} />
        <Search scrolled={scroll || isSurvey} />
        <Navigation scrolled={scroll || isSurvey} />
      </Content>
    </Wrapper>
  );
};

export default Header;
