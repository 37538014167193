import React, { FC, useState } from 'react';
import styled from 'styled-components';
import addToMailchimp from 'gatsby-plugin-mailchimp';

import { colors } from 'src/theme/colors';

const Form = styled.form`
  width: 100%;
`;

const Wrapper = styled.div`
  align-items: center;
  display: flex;
  height: 32px;
  position: relative;
`;

const Input = styled.input`
  border: 1px solid ${colors.blue500};
  border-radius: 10px;
  font-size: 12px;
  height: 100%;
  padding: 0 110px 0 10px;
  width: 100%;

  &::placeholder {
    color: ${colors.blue500};
  }
`;

const Button = styled.button`
  background-color: ${colors.blue500};
  border: none;
  border-bottom-right-radius: 10px;
  border-top-right-radius: 10px;
  color: ${colors.white};
  cursor: pointer;
  font-size: 10px;
  font-weight: 700;
  height: 100%;
  padding: 0 12px;
  position: absolute;
  right: 0;
  text-transform: uppercase;
  top: 0;
`;

const Message = styled.p<NewsletterTypes>`
  color: ${props =>
    props.isError ? `${colors.orange500}` : `${colors.blue500}`};
  font-size: 12px;
  margin: 10px 0 0;
`;

interface NewsletterTypes {
  isError?: boolean;
}

const Newsletter: FC<NewsletterTypes> = () => {
  const [email, setEmail] = useState<string>('');
  const [message, setMessage] = useState<string>('');
  const [error, setError] = useState<boolean>(false);

  const handleSubmit = e => {
    e.preventDefault();

    addToMailchimp(email)
      .then(data => {
        if (data.result === 'success') {
          setError(false);
          setMessage('Ačiū. Laukite naujienų savo el. pašto dėžutėje!');
        } else if (data.result === 'error') {
          setError(true);
          setMessage(
            'Prenumerata nepavyko. Patikrinkite įvestą el. pašto adresą ir bandykite dar kartą.'
          );
        }
      })
      .catch(error => {
        console.log(error);
      });
  };

  const handleEmailChange = event => {
    setEmail(event.currentTarget.value);
  };

  return (
    <Form onSubmit={handleSubmit}>
      <Wrapper>
        <Input
          placeholder="Jūsų el. paštas"
          name="email"
          type="text"
          onChange={handleEmailChange}
        />
        <Button type="submit">Prenumeruoti</Button>
      </Wrapper>
      {message && <Message isError={error}>{message}</Message>}
    </Form>
  );
};

export default Newsletter;
