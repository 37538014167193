import React, { FC } from 'react';
import styled, { css } from 'styled-components';
import { Link } from 'gatsby';
import { StaticImage } from 'gatsby-plugin-image';

import { mediaQuery } from 'src/theme/breakpoints';

const Wrapper = styled.div<Pick<LogoProps, 'scrolled'>>`
  width: 70px;

  & img {
    cursor: pointer;
  }

  ${mediaQuery('md')} {
    width: 120px;
  }

  ${({ scrolled }) =>
    scrolled &&
    css`
      filter: invert(100%);
      transform: scale(0.8);
    `}
`;

interface LogoProps {
  scrolled?: boolean;
}

const Logo: FC<LogoProps> = ({ scrolled }) => {
  return (
    <Link to="/">
      <Wrapper scrolled={scrolled}>
        <StaticImage
          src="../../assets/images/logo.png"
          placeholder="tracedSVG"
          alt="Kelionių organizatorius Piligrimas"
        />
      </Wrapper>
    </Link>
  );
};

export default Logo;
