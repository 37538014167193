import React, { FC } from 'react';
import styled, { css } from 'styled-components';
import { Link } from 'gatsby';

import { ButtonAppearance } from './constants';
import { colors } from 'src/theme/colors';
import { mediaQuery } from 'src/theme/breakpoints';

export const baseButtonStyles = css`
  align-items: center;
  background-color: ${colors.blue500};
  border: none;
  border-radius: 10px;
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.19), 0 6px 6px rgba(0, 0, 0, 0.23);
  box-sizing: border-box;
  color: ${colors.white};
  cursor: pointer;
  display: flex;
  font-size: 14px;
  font-weight: 700;
  height: 40px;
  justify-content: center;
  letter-spacing: 0.8px;
  padding: 0 20px;
  text-align: center;
  text-decoration: none;
  text-transform: uppercase;
  transition: all 300ms;

  &:hover {
    background-color: ${colors.orange500};
    box-shadow: 0 19px 38px rgba(0, 0, 0, 0.3), 0 15px 12px rgba(0, 0, 0, 0.22);
    transform: translateY(-3px);
  }

  &:focus:not(:focus-visible) {
    outline: 0;
  }
`;

export const ButtonAppearancesStyles = css<Pick<ButtonProps, 'appearance'>>`
  ${({ appearance }) =>
    appearance === ButtonAppearance.ALFA &&
    css`
      ${baseButtonStyles}
    `}

  ${({ appearance }) =>
    appearance === ButtonAppearance.BRAVO &&
    css`
      ${baseButtonStyles}
      background-color: ${colors.orange500};
      padding: 10px;

      &:hover {
        background-color: ${colors.blue500};
      }

      ${mediaQuery('md')} {
        font-size: 18px;
        height: 60px;
        width: 536px;
      } ;
    `}

    ${({ appearance }) =>
    appearance === ButtonAppearance.CHARLIE &&
    css`
      ${baseButtonStyles}
      font-size: 18px;
      height: 100%;
      max-width: 260px;
      max-height: 140px;
      min-height: 40px;
      width: 100%;
    `}
`;

const StyledButton = styled(Link)`
  ${ButtonAppearancesStyles}
`;

const StyledLink = styled.a`
  ${ButtonAppearancesStyles}
`;

interface ButtonProps {
  appearance: ButtonAppearance;
  children: React.ReactNode | string;
  url: string;
  isExternal?: boolean;
}

const Button: FC<ButtonProps> = ({ appearance, url, children, isExternal }) => (
  <>
    {isExternal ? (
      <StyledLink appearance={appearance} href={url} rel="noopener noreferrer">
        {children}
      </StyledLink>
    ) : (
      <StyledButton to={url} appearance={appearance}>
        {children}
      </StyledButton>
    )}
  </>
);

export default Button;
