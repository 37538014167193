import { Link } from 'gatsby';
import styled from 'styled-components';
import React, { FC, useState, useRef } from 'react';

import Burger from './Burger';
import IconLabel, { IconSize } from 'src/components/IconLabel';
import Dropdown from 'src/components/Dropdown';
import {
  PlaneIcon,
  CompassIcon,
  PhoneIcon,
  SkiIcon,
  BeachIcon,
  LocationIcon,
} from 'src/assets/icons';
import { mediaQuery } from 'src/theme/breakpoints';
import { useOnClickOutside } from 'src/utils/hooks';
import { colors } from 'src/theme/colors';
import { links } from 'src/components/MultistepForm/shared/constants';

const StyledNavigation = styled.nav<NavigationProps>`
  align-items: center;
  display: grid;
  grid-column-gap: 42px;
  grid-template-columns: repeat(3, auto);
  justify-content: space-between;

  ${mediaQuery('lg', 'max')} {
    align-items: flex-start;
    background-color: ${colors.blue100};
    display: flex;
    flex-direction: column;
    height: 100vh;
    justify-content: flex-start;
    padding: 100px 20px;
    position: absolute;
    right: 0;
    top: 0;
    transform: ${props =>
      props.isOpen ? 'translateX(0)' : 'translateX(100%)'};
    transition: transform 0.3s ease-in-out;
    width: 260px;

    & > * {
      border-top: 1px solid ${colors.black};
      display: block;
      padding: 20px 0;
      width: 100%;
    }

    & > :last-child {
      border-bottom: 1px solid ${colors.black};
    }
  }

  & a {
    text-decoration: none;
  }
`;

interface NavigationProps {
  scrolled?: boolean;
  isOpen?: boolean;
}

const Navigation: FC<NavigationProps> = ({ scrolled }) => {
  const [open, setOpen] = useState(false);
  const node = useRef() as React.MutableRefObject<HTMLDivElement>;

  useOnClickOutside(node, () => setOpen(false));

  return (
    <div ref={node}>
      <StyledNavigation isOpen={open}>
        <Dropdown
          icon={<PlaneIcon />}
          label="Kelionės"
          iconSize={IconSize.SMALL}
          isDark={scrolled || open}
          closeOnDropdownClick={true}
        >
          <Link to={links.ACTIVITIES} onClick={() => setOpen(false)}>
            <IconLabel
              icon={<SkiIcon />}
              label="Pagal veiklas"
              size={IconSize.SMALL}
              isDark
            />
          </Link>
          <Link to={links.COUNTRIES} onClick={() => setOpen(false)}>
            <IconLabel
              icon={<LocationIcon />}
              label="Pagal šalis"
              size={IconSize.SMALL}
              isDark
            />
          </Link>
          <Link to={links.SEASONS} onClick={() => setOpen(false)}>
            <IconLabel
              icon={<BeachIcon />}
              label="Pagal sezoną"
              size={IconSize.SMALL}
              isDark
            />
          </Link>
        </Dropdown>
        <Link to="/unikali-kelione" onClick={() => setOpen(false)}>
          <IconLabel
            icon={<CompassIcon />}
            label="Susikurk maršrutą"
            size={IconSize.SMALL}
            isDark={scrolled || open}
          />
        </Link>
        <a href="tel:837320756">
          <IconLabel
            icon={<PhoneIcon />}
            label="(837) 320 756"
            size={IconSize.SMALL}
            isDark={scrolled || open}
          />
        </a>
      </StyledNavigation>
      <Burger isOpen={open} setIsOpen={setOpen} isDark={scrolled || open} />
    </div>
  );
};

export default Navigation;
